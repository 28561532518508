// Store.jsx
import { useEffect } from "react";

export default function Store() {
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isAndroid = userAgent.indexOf("android") > -1;
    const isIOS =
      userAgent.indexOf("iphone") > -1 ||
      userAgent.indexOf("ipad") > -1 ||
      userAgent.indexOf("ipod") > -1;

    const redirectToStore = () => {
      if (isAndroid) {
        // 안드로이드 기기 전환 추적 및 리디렉션
        window.gtag_report_conversion_android(
          "https://play.google.com/store/apps/details?id=com.ceunnseo.reMIND"
        );
      } else if (isIOS) {
        // iOS 기기 전환 추적 및 리디렉션
        window.gtag_report_conversion_ios(
          "https://apps.apple.com/kr/app/%EB%A6%AC%EB%A7%88%EC%9D%B8%EB%93%9C-ai-%EA%B0%95%EC%95%84%EC%A7%80%EC%99%80-%ED%95%A8%EA%BB%98%ED%95%98%EB%8A%94-%ED%9E%90%EB%A7%81%EC%B1%84%ED%8C%85/id6544783154"
        );
      } else {
        window.location.href = "/";
      }
    };

    redirectToStore();
  }, []);

  return <></>;
}

// gtag_report_conversion_android 및 gtag_report_conversion_ios 함수는 이미 index.html에 정의되어 있습니다.
// Google Ads 전환 추적 로직을 올바르게 처리하도록 포함되어 있는지 확인하세요.
