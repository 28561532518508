import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import HeaderImage from "../../assets/img/header-img.png";
import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";
import { Pallate } from "../../assets/pallate";

export default function Header() {
  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide className="flexCenter">
        <div>
          <h1 className="extraBold font50">
            언제나 곁에 있는 마음 지킴이, reMIND
          </h1>
          <HeaderP className="font18 semiBold">
            &nbsp;지치고 힘든 날, 마음을 터놓을 곳이 없어 답답했던 순간들.
            reMIND는 그런 순간에 당신의 곁에서 언제나 귀 기울여줍니다. <br />
            <br />
            &nbsp;당신의 감정을 이해하고, 필요한 위로와 공감을 건네며, 편안한
            대화를 통해 마음의 무게를 덜어줍니다. <br />
            <br />
            &nbsp;reMIND는 단순한 챗봇을 넘어, 당신의 감정의 변화를 기록하고
            분석하여 언제든지 돌아볼 수 있는 마음의 안식처가 되어줍니다.
          </HeaderP>
          <BtnWrapper>
            <FullButton
              title="App Store"
              action={() => {
                window.open(
                  "https://apps.apple.com/kr/app/remind/id6544783154",
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
            />
            <FullButton
              title="Play Store"
              action={() => {
                window.open(
                  "https://play.google.com/store/apps/details?id=com.ceunnseo.reMIND",
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
            />
          </BtnWrapper>
        </div>
      </LeftSide>
      <RightSide>
        <ImageWrapper>
          <Img
            className="radius8"
            src={HeaderImage}
            alt="office"
            style={{ zIndex: 9 }}
          />
        </ImageWrapper>
      </RightSide>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 100%;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 360px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  gap: 24px; /* Add this line */
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
  img {
    max-width: 100%;
    max-height: 700px;
    width: auto;
    height: auto;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;
