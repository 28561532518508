import React from "react";
import { Helmet } from "react-helmet";
// Screens
import Landing from "./screens/Landing.jsx";
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import Store from "./screens/Store.jsx";
import Tob from "./screens/Tob.jsx";

export default function App() {
  return (
    <div className='App'>
      <BrowserRouter>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link rel="stylesheet" as="style" crossorigin href="https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css" />
      </Helmet>
      <Routes>
        <Route path="/" element={<Landing />}></Route>
        <Route path="/store" element={<Store />}></Route>
        <Route path="/tob-form" element={<Tob />}></Route>
        <Route path="/*" element={< ></>}></Route>
      </Routes>
      </BrowserRouter>
    </div>
  );
}

