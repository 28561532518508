import React from "react";
import styled from "styled-components";
import { Pallate } from "../../assets/pallate";

export default function FullButton({ title, action, border }) {
  return (
    <Wrapper
      className="animate pointer radius8"
      onClick={action ? () => action() : null}
      border={border}
    >
      {title}
    </Wrapper>
  );
}

const Wrapper = styled.button`
  border: 1px solid
    ${(props) => (props.border ? Pallate.gray : Pallate.priamry)};
  background-color: ${(props) =>
    props.border ? "transparent" : Pallate.priamry};
  width: 100%;
  padding: 12px;
  outline: none;
  color: ${(props) => (props.border ? Pallate.gray : Pallate.white)};
  font-size: 1rem; /* Adjust the value as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: auto;
  :hover {
    background-color: ${(props) =>
      props.border ? "transparent" : Pallate.secondary};
    border: 1px solid ${Pallate.priamry};
    color: ${(props) => (props.border ? Pallate.priamry : Pallate.white)};
  }
`;
